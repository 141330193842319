import axios from '@/plugins/axios';
import { BUYER_GROUP_TYPE, SELLER_GROUP_TYPE, STATUS } from './constants';

class OrderDistributeNoManageApi {
  static getDistributeNoOrders(
    page = 1,
    limit = 10,
    buyerGroupType = BUYER_GROUP_TYPE.ALL,
    buyerName = '',
    sellerGroupType = SELLER_GROUP_TYPE.ALL,
    sellerName = '',
    startDate = '',
    endDate = '',
    status = STATUS.ALL,
  ) {
    return axios({
      url: '/api/order/freqcard/page',
      data: {
        page,
        limit,
        buyer_group_type: buyerGroupType,
        buyer_name: buyerName,
        seller_group_type: sellerGroupType,
        seller_name: sellerName,
        start_date: startDate,
        end_date: endDate,
        status, // TODO: 接口参数缺失
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询次卡订单列表',
        },
      },
    });
  }

  static export(data) {
    return axios({
      url: '/api/order/freqcard/export',
      method: 'post',
      data,
      headers: {
        meta: {
          skipSuccess: true,
          desc: '导出',
        },
      },
    });
  }
}

export default OrderDistributeNoManageApi;
