import DataTransferUtil from '@/utils/data-transfer.util';

export const ORDER_DISTRIBUTE_NO_COLUMNS = [
  {
    title: '订单号',
    key: 'order_no',
    width: 140,
    fixed: 'left',
  },
  {
    title: '买方类型',
    key: 'buyer_group_type',
    slot: 'buyerGroupType',
    width: 100,
  },
  {
    title: '买方',
    key: 'buyer_name',
    width: 100,
  },
  {
    title: '卖方类型',
    key: 'seller_group_type',
    slot: 'sellerGroupType',
    width: 100,
  },
  {
    title: '卖方',
    key: 'seller_name',
    width: 120,
  },
  {
    title: '充值类型',
    key: 'order_type',
    slot: 'orderType',
    width: 100,
  },
  {
    title: '次数',
    key: 'num',
    width: 70,
  },
  {
    title: '操作人',
    key: 'opt_nick_name',
    width: 120,
  },
  {
    title: '订单状态',
    key: 'status',
    slot: 'status',
    width: 100,
  },
  {
    title: '创建时间',
    key: 'create_time',
    width: 180,
  },
  {
    title: '撤销时间',
    key: 'cancel_time',
    minWidth: 180,
  },
];

export const BUYER_GROUP_TYPE = {
  CHANNEL: 'channel',
  STORE: 'store',
  CUSTOMER: 'customer',
  ALL: 'all',
};

export const BASIC_BUYER_GROUP_TYPE_TEXT = {
  [BUYER_GROUP_TYPE.CHANNEL]: '渠道',
  [BUYER_GROUP_TYPE.STORE]: '门店',
  [BUYER_GROUP_TYPE.CUSTOMER]: '消费者',
};

export const BUYER_GROUP_TYPE_TEXT = {
  ...BASIC_BUYER_GROUP_TYPE_TEXT,
  [BUYER_GROUP_TYPE.ALL]: '全部',
};

export const BUYER_GROUP_TYPE_OPTIONS = DataTransferUtil
  .enumTransfer2Options(BUYER_GROUP_TYPE_TEXT);

export const SELLER_GROUP_TYPE = {
  SYSTEM: 'sys',
  CHANNEL: 'channel',
  STORE: 'store',
  ALL: 'all',
};

export const BASIC_SELLER_GROUP_TYPE_TEXT = {
  [SELLER_GROUP_TYPE.SYSTEM]: '运营',
  [SELLER_GROUP_TYPE.CHANNEL]: '渠道',
  [SELLER_GROUP_TYPE.STORE]: '门店',
};

export const SELLER_GROUP_TYPE_TEXT = {
  ...BASIC_SELLER_GROUP_TYPE_TEXT,
  [SELLER_GROUP_TYPE.ALL]: '全部',
};

export const SELLER_GROUP_TYPE_OPTIONS = DataTransferUtil
  .enumTransfer2Options(SELLER_GROUP_TYPE_TEXT);

export const ORDER_TYPE = {
  GIFT: 0,
  PURCHASE: 1,
};

export const ORDER_TYPE_TEXT = {
  [ORDER_TYPE.GIFT]: '赠送',
  [ORDER_TYPE.PURCHASE]: '购买',
};

export const STATUS = {
  REVOKE: 0,
  SUCCESS: 1,
  ALL: 'all',
};

export const BASIC_STATUS_TEXT = {
  [STATUS.REVOKE]: '撤销',
  [STATUS.SUCCESS]: '成功',
};

export const STATUS_TEXT = {
  ...BASIC_STATUS_TEXT,
  [STATUS.ALL]: '全部',
};

export const STATUS_OPTIONS = DataTransferUtil.enumTransfer2Options(STATUS_TEXT);
