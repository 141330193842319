var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-distribute-no-manage"},[_c('app-query-bar',{attrs:{"query-config":_vm.queryConfig},on:{"onSearch":_vm.fetchDistributeNoOrders},model:{value:(_vm.queryData),callback:function ($$v) {_vm.queryData=$$v},expression:"queryData"}}),_c('app-export-bar',{on:{"onExport":_vm.onExport,"onExportAll":_vm.onExportAll}}),_c('app-data-table',{attrs:{"columns":_vm.ORDER_DISTRIBUTE_NO_COLUMNS,"data":_vm.distributeNoOrders},on:{"onPageConfigChange":_vm.fetchDistributeNoOrders},scopedSlots:_vm._u([{key:"buyerGroupType",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.BUYER_GROUP_TYPE_TEXT[row.buyer_group_type])+" ")]}},{key:"sellerGroupType",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.SELLER_GROUP_TYPE_TEXT[row.seller_group_type])+" ")]}},{key:"orderType",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.ORDER_TYPE_TEXT[row.order_type])+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.STATUS_TEXT[row.status])+" ")]}}]),model:{value:(_vm.queryData),callback:function ($$v) {_vm.queryData=$$v},expression:"queryData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }