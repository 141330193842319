<template>
  <div class="order-distribute-no-manage">
    <app-query-bar
      :query-config="queryConfig"
      v-model="queryData"
      @onSearch="fetchDistributeNoOrders" />
    <app-export-bar
      @onExport="onExport"
      @onExportAll="onExportAll" />
    <app-data-table
      :columns="ORDER_DISTRIBUTE_NO_COLUMNS"
      :data="distributeNoOrders"
      v-model="queryData"
      @onPageConfigChange="fetchDistributeNoOrders">
      <template
        slot-scope="{ row }"
        slot="buyerGroupType">
        {{ BUYER_GROUP_TYPE_TEXT[row.buyer_group_type] }}
      </template>
      <template
        slot-scope="{ row }"
        slot="sellerGroupType">
        {{ SELLER_GROUP_TYPE_TEXT[row.seller_group_type] }}
      </template>
      <template
        slot-scope="{ row }"
        slot="orderType">
        {{ ORDER_TYPE_TEXT[row.order_type] }}
      </template>
      <template
        slot-scope="{ row }"
        slot="status">
        {{ STATUS_TEXT[row.status] }}
      </template>
    </app-data-table>
  </div>
</template>

<script>
import {
  ORDER_DISTRIBUTE_NO_COLUMNS,
  BUYER_GROUP_TYPE,
  BUYER_GROUP_TYPE_TEXT,
  BUYER_GROUP_TYPE_OPTIONS,
  SELLER_GROUP_TYPE,
  SELLER_GROUP_TYPE_TEXT,
  SELLER_GROUP_TYPE_OPTIONS,
  ORDER_TYPE_TEXT,
  STATUS_TEXT,
  STATUS_OPTIONS,
} from './constants';
import OrderDistributeNoManageApi from './order-distribute-no-manage.api';
import DataTransferUtil from '@/utils/data-transfer.util';

export default {
  name: 'order-distribute-no-manage',
  data() {
    return {
      ORDER_DISTRIBUTE_NO_COLUMNS,
      BUYER_GROUP_TYPE_TEXT,
      SELLER_GROUP_TYPE_TEXT,
      ORDER_TYPE_TEXT,
      STATUS_TEXT,
      queryConfig: [
        {
          fieldName: 'buyerGroupType',
          name: '买方类型',
          type: 'select',
          options: BUYER_GROUP_TYPE_OPTIONS,
        },
        {
          fieldName: 'buyerName',
          name: '买方',
        },
        {
          fieldName: 'status',
          name: '状态',
          type: 'select',
          options: STATUS_OPTIONS,
        },
        {
          fieldName: 'dateRange',
          name: '时间',
          type: 'dateRange',
        },
        {
          fieldName: 'sellerGroupType',
          name: '卖方类型',
          type: 'select',
          options: SELLER_GROUP_TYPE_OPTIONS,
        },
        {
          fieldName: 'sellerName',
          name: '卖方',
          placeholder: '卖方名称',
        },
      ],
      queryData: {
        page: 1,
        limit: 10,
        count: 0,
        buyerGroupType: BUYER_GROUP_TYPE.ALL,
        buyerName: '',
        status: '',
        sellerGroupType: SELLER_GROUP_TYPE.ALL,
        sellerName: '',
        dateRange: ['', ''],
      },
      distributeNoOrders: [],
    };
  },
  created() {
    this.fetchDistributeNoOrders();
  },
  methods: {
    async onExport() {
      const {
        page,
        limit,
        buyerGroupType,
        buyerName,
        status,
        sellerGroupType,
        sellerName,
        dateRange,
      } = this.queryData;
      const [startDate, endDate] = dateRange;

      const key = await OrderDistributeNoManageApi.export({
        page,
        limit,
        buyer_group_type: buyerGroupType,
        buyer_name: buyerName,
        seller_group_type: sellerGroupType,
        seller_name: sellerName,
        start_date: startDate,
        end_date: endDate,
        status,
      });
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    async  onExportAll() {
      const key = await OrderDistributeNoManageApi.export();
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    async fetchDistributeNoOrders() {
      const {
        page,
        limit,
        buyerGroupType,
        buyerName,
        status,
        sellerGroupType,
        sellerName,
        dateRange,
      } = this.queryData;
      const [startDate, endDate] = dateRange;

      const {
        count = 0,
        results = [],
      } = await OrderDistributeNoManageApi.getDistributeNoOrders(
        page,
        limit,
        buyerGroupType,
        buyerName,
        sellerGroupType,
        sellerName,
        startDate,
        endDate,
        status,
      );

      this.distributeNoOrders = results;
      this.$set(this.queryData, 'count', count);
    },
  },
};
</script>

<style scoped>

</style>
